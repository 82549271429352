/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React from "react";

import Layout from "../layout";
import SEO from "../other/seo";

import WishList from "../components/AC-WishList/components/WishList";

const wishlist = () => {
  return (
    <Layout>
      <SEO title="Wishlist" />
      <WishList />
    </Layout>
  );
};

export default wishlist;
