import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PROJECT_LINK, PREVIEW } from "../../../project-config";
import * as classes from "../Styles/WishListItems.module.css";
import { Link, navigate } from "gatsby";
import handleDeleteProductFromWishList from "./handleDeleteProductFromWishList";
import handleMoveProductFromWishList from "./handleMoveProductFromWishList";
import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
import htmldecoder, { htmlDecode } from "../../../functions/htmldecoder";
import PriceTag from "../../../shared/components/PriceTag/PriceTag";
import { handleReplaceImagesWithLargeImagesOnError } from "../../../functions/Utilities";
import { fetchWishList, totalWishlistCount } from "../../../redux/actions/wishListActions";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function WishListMobile({ show, close, toggleWish, wishlistCount }) {

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );


  const wishListStates = useSelector(
    state => state.wishListReducer,
    shallowEqual
  );

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const [open1, setOpen1] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
  };


  console.log('rrrrrr', wishListStates);

  const dispatch = useDispatch();

  const { langCode, currency, translate, priceConvert } =
    React.useContext(I18nContext);

  const renderWishList = () => {
    if (wishListState && wishListState.length > 0) {
      return wishListState.map(wishItem => renderWishListItem(wishItem));
    } else {
      return (
        <div className="noItemsFoundWishlist">
          {translate("js.header.wishlist.noitems")}
        </div>
      );
    }
  };

  const handleWishItemClicked = seoUrl => {
    close();
    navigate(seoUrl);
  };

  const handleMoveItemFromWishList = (event, code, id, dispatch) => {
    console.log("xxxxxx", handleMoveProductFromWishList);
    event.stopPropagation();
    setTimeout(() => setOpen1(true), 1500)
    setTimeout(() => setOpen1(false), 4000)
    handleMoveProductFromWishList(code, id, dispatch);
  }








  const handleDeleteItemFromWishList = (event, id, dispatch) => {
    console.log("wishlist001", handleDeleteProductFromWishList);
    event.stopPropagation();
    //setOpen(true);
    setTimeout(() => setOpen(true), 1000)
    setTimeout(() => setOpen(false), 400000)
    handleDeleteProductFromWishList(id, dispatch);
  };

  const renderWishListItem = wishItem => {
    let { itemid, title, code, prices, image, seoUrl, longdesc } = wishItem.item;

    let imageKitImage = `https://ik.imagekit.io/ofb/dev/store/20180522154/assets/items/largeimages/${code}.jpg?tr=ar-1-1,dpr-2,pr-true,f-auto,h-150`;

    let url = `/${seoUrl.replace('product/', '')}`;
    //let description =
    // desc && typeof desc === "string" && desc.replace('\\"', '"');
    return (
      <>

        <Grid item xs={3}>
          <div
            className="itemcard-itemCard"
            //onClick={() => handleWishItemClicked(seoUrl)}
            key={itemid}
          >
            <div className="itemcard-itemImageDiv">
              <Link to={url}><img
                className="itemcard-itemImage"
                src={imageKitImage.replace('/images/', '/largeimages/')}
                alt={`${title}`}
              //onError={handleReplaceImagesWithLargeImagesOnError}
              /></Link>
              {/* {wishlistid} */}
            </div>
            <div className="itemcard-removeIcon">
              <button
                // onClick={e => {
                //   e.stopPropagation();
                //   toggleWish(
                //     e,
                //     id,
                //     code,
                //     title,
                //     desc,
                //     currency_sign,
                //     image,
                //     price,
                //     url
                //   );
                // }}
                style={{ width: "100%", background: "none" }}

                onClick={event =>
                  handleDeleteItemFromWishList(event, itemid, dispatch)
                }
              >
                {/*{translate("wishlistMobile.removeButtonText")}*/}

                <i className="material-icons">close</i>
              </button>
            </div>
            <div className="itemcard-itemActions">
              <div className="itemdetails-itemDetails">
                <div className="titleandprice">
                  <p className="itemdetails-itemDetailsLabel">

                    <Link to={url}>{title}</Link></p>
                  <div className="itemdetails-itemPricing">
                    $ {prices[0].listprice}
                    {/* {prices.value && (
              <PriceTag
                value={{
                  integer:
                  prices.type !== "empty"
                      ? typeof prices.value.integer === "string" &&
                      prices.value.integer.includes("$")
                        ? prices.value.integer
                            .replace("$", "")
                            .split(".")[0]
                            .replace(",", "")
                        : prices.value.integer.replace(",", "")
                      : 0,
                  decimal:
                  prices.type !== "empty"
                      ? typeof prices.value.decimal === "string" &&
                      prices.value.decimal.includes("$")
                        ? prices.value.decimal.replace("$", "").split(".")[0]
                        : prices.value.decimal
                      : 0
                }}
              />
            )}  */}
                  </div>
                </div>
                <p
                  className="itemdetails-itemDetailsDescription"
                  dangerouslySetInnerHTML={{
                    __html: htmldecoder(longdesc)
                  }}
                ></p>
              </div>


              <div className="itemcard-actionDiv">
                <span className="itemcard-flex"
                  onClick={event =>
                    handleMoveItemFromWishList(event, code, itemid, dispatch)
                  }> ADD TO CART</span>
              </div>

            </div>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <div>

        <Grid>




          <div>
            <div className="index-wishListContainer">
              <div className="mywishlistTitle">My Wishlist
                <span
                  style={
                    wishListState.length > 0
                      ? null
                      : { display: "none" }
                  }
                >
                  {wishListState.length} Items
                </span>
                {/* //  {totalWishlistCount}  */}
              </div>
              {renderWishList()}
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Alert onClose={handleClose} severity="success">
                  Item Removed from Wishlist
                </Alert>
              </Snackbar>
              <Snackbar
                open={open1}
                autoHideDuration={6000}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Alert onClose={handleClose1} severity="success">
                  Item Added to Basket
                </Alert>
              </Snackbar>
            </div>
            {/* <Pagination /> */}
          </div>
        </Grid>
      </div>
    </div>
  );
}
