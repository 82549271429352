import { WISHLIST_PRODUCT_MOVE_TO_CART, GET_BASKETS } from "../../../redux/links";
import { REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE_CART,  FETCH_SUPPLIERS_BASKET_SUCCESS } from "../../../redux/types";
import {
  fetchWishList,
  setWishListLoadingAfterUpdateCart
} from "../../../redux/actions/wishListActions";


const handleMoveProductFromWishlist = (code, itemid, dispatch) => {
  let form = new FormData();

  form.append("mode", "addItems");
  form.append("addfrom", "wishlist");
  form.append("clientid", "3367");
  form.append("vid", "20210820389");
  form.append("ic1", `${code}`);
  form.append("qty1", "1");
  form.append("return", "json");

  dispatch(setWishListLoadingAfterUpdateCart(true));

  fetch(WISHLIST_PRODUCT_MOVE_TO_CART(code), {
    method: "POST",
    body: form,
    headers: {
      Accept: "*/*",
      credentials: "same-origin"
    },
    mimeType: "multipart/form-data",
    data: form
  })
    .then(res => {
      dispatch({ type: REQUEST_WISHLIST_AFTER_WISHLIST_UPDATE_CART });

      fetch(GET_BASKETS())
      .then(result => result.json())
      .then(data => {     
         dispatch({ type:  FETCH_SUPPLIERS_BASKET_SUCCESS, payload: data.__Result});
      });
      dispatch(fetchWishList());
    })
    // 
    .catch(err => {
      console.error("basket update failed", err.message);
      dispatch(setWishListLoadingAfterUpdateCart(false));
    });
};

export default handleMoveProductFromWishlist;
